import { ref } from 'vue';
import { parseData } from '@devhacker/shared/utils/parseData';
import { pushBadge } from '~/components/Pusher/websockets-functions';
import { SyncArticleViewsCountSchema } from '~/schemas/views';
import { useArticlesStore } from '~/store/articles';

export function useUpdatingViewsCount() {
  const articleStore = useArticlesStore();
  const loadingViewsIds = ref([]);
  const app = useNuxtApp();

  const updateViewsCount = async (postId) => {
    if (getLoadingViews(postId)) {
      return;
    }

    try {
      loadingViewsIds.value.push(postId);
      const { data: responseData } = await app.$viewsApi.syncArticleViewsCount(postId);
      const { viewsCount } = parseData(responseData, SyncArticleViewsCountSchema);

      const payload = {
        [postId]: viewsCount,
      };
      articleStore.updateViewsCounts(payload);
    } catch (error) {
      pushBadge({ content: 'Ошибка. Попробуйте еще раз.', isError: true });
    } finally {
      loadingViewsIds.value = loadingViewsIds.value.filter((id) => id !== postId);
    }
  };

  const getLoadingViews = (postId) => {
    return loadingViewsIds.value.includes(postId);
  };

  return {
    loadingViewsIds,
    updateViewsCount,
    getLoadingViews,
  };
}
